<template>
    <div class="conetents">
        <div class="c-header">
            <div class="font " style="width: 18%;">流程名</div>
            <div class="font " style="width: 18%;">摘要</div>
            <div class="font" style="width: 11%;">发起人</div>
            <div class="font" style="width: 14%;">所属分组</div>
            <div class="font" style="width: 13%;">提交时间</div>
            <div class="font" style="width: 13%; ">结束时间</div>
            <div class="font" style="width: 13%;">流程状态</div>
        </div>
        <div class="c-list" v-infinite-scroll="getList" v-loading="loading" infinite-scroll-delay="200" infinite-scroll-disabled="disabled">
            <div class="c-list-box" v-for="(row, index) in tableData" :key="index" @click="handleClick(row)">
                <div class="list-box" style="width: 18%; padding-left: 0px;">
                    <div style="margin: auto; max-width: 150px;">
                        <div class="header-bg">
                            <img style="width: 24px; height: 24px;border-radius: 50%;"
                                :src="row.processInstance.avatarUrl !== null ? row.processInstance.avatarUrl : 'https://source.fungsong.com/3451926509266j7jEgm441uQs.png'"
                                alt="">
                            <div class="text-cs" style="line-height: 24px; margin-left: 8px; color: #5669ec;"
                                @click.stop="openUserDetail(row.processInstance.startUserId)">
                                {{ row.processInstance.startUserName }} <span
                                    style="color:rgba(27, 27, 27, 1);">提交的</span>
                            </div>
                        </div>
                        <div class="text-cs" style="text-align: left;width: 150px;  overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                            {{ row.processInstance.name }}
                        </div>
                    </div>
                </div>
                <div class="list-box text-cs" style="width: 18%;">
                    <div v-if="row.digest">
                        <div v-if="row.digest.length&&row.digest.length < 4">
                            <div v-for="(item, index) in row.digest" :key="index"  :style="'text-align: center;width: 100%;  overflow: hidden;text-overflow: ellipsis;white-space: nowrap;'">{{ item.name }}:{{ item.value }}
                            </div>
                        </div>
                        <div v-else>
                            <div v-for="(item, index) in row.digest" :key="index" v-show="index < 3 || row.isShow"  :style="row.isShow ? '' :'width: 100%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;'">
                                {{ item.name }}:{{
            item.value }}
                            </div>
                            <div @click.stop="row.isShow = !row.isShow" style="color:#1890ff;cursor:pointer">{{
            row.isShow ? '收起' : '展开' }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="list-box" style="width: 12%; padding-left: 0px;">
                    <template v-if="row.processInstance.startUserName">
                        <div class="text-cs" style="line-height: 24px; margin-left: 8px; color: #5669ec;"
                            @click.stop="openUserDetail(row.processInstance.startUserId)">
                            {{ row.processInstance.startUserName }}
                        </div>
                    </template>
                    <template v-else>-</template>
                </div>
                <div class="list-box text-cs" style="width: 14%;">
                    {{ row.groupName || "暂无" }}
                </div>
                <div class="list-box text-cs" style="width: 13%;">
                    <span>{{ parseTime(row.createTime) }}</span>
                </div>
                <div class="list-box text-cs" style="width: 13%; ">
                    <span>{{ parseTime(row.endTime) || "-" }}</span>
                </div>
                <div class="list-box text-cs" style="width: 13%; ">
                    <span v-if="row.result == 1" style="color: #5669ec;">进行中</span>
                    <span v-if="row.result == 2" style="color: #67c23a;">已通过</span>
                    <span v-if="row.result == 3" style="color: #f83e45;">不通过</span>
                    <span v-if="row.result == 4" style="color: #848484;">已撤回</span>
                </div>
            </div>

            
            <div v-if="tableData.length == 0" style="text-align: center; margin-top: 100px;">
                <img src="https://source.fungsong.com/3453288888914YKG1W1O2ZTgm.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import { getProcessReceivedList } from '@/api/examines.js'
import { parseTime } from "@/utils/time";
export default {
    props: {
        showNewly: {
            type: Object,
            default: {},
        },
    },
    data() {
        return {
            loading: true,
            tableData: [], // 数据列表
            page: 1,//即将搜索页数
            pageSize: 15,//每次搜索条数
            createTime: [],
            formIds: [],
            name: '',
            bank: true,//是否开启下一次搜索
            oldPage: 0, //当前搜索页数
            parseTime,
            maxHeight: 0,
            total: 1,
            soType: 0,//筛选请求
            status: '',//状态
            creator: '',
            disabled: false,
        }
    },
    watch: {
        showNewly: {
            deep: true,
            handler(newVal, oldVal) {
                console.log(newVal);
                this.createTime = newVal.createTime
                this.formIds = newVal.formIds
                this.name = newVal.name
                this.page = 1
                this.oldPage = 0
                console.log(newVal.name);
                this.bank = true//是否开启下一次搜索
                this.tableData = []
                this.status = newVal.status
                this.creator = newVal.sponsor
                if (newVal.name != undefined) {
                    this.soType = 1
                } else {
                    this.soType = 0
                }
                this.getList()
            },
        },
    },
    created() {
        this.getList()
        this.calculateMaxHeight()
    },
    mounted() {
        this.calculateMaxHeight();
    },
    methods: {
        refresh() {
            this.createTime = []
            this.formIds = []
            this.name = ''
            this.page = 1
            this.oldPage = 0
            this.bank = true//是否开启下一次搜索
            this.tableData = []
            this.status = ''
            this.creator = ''
            this.soType = 1
            this.getList()
        },
        openUserDetail(id) {
            this.openUserCard(id)
        },
        calculateMaxHeight() {
            // 计算窗口高度减去一定的偏移量
            const windowHeight = window.innerHeight;
            const offset = 280; // 偏移量可以根据实际情况调整
            this.maxHeight = windowHeight - offset;
        },
        getList() {
            this.disabled = true
            console.log('jinnn');
            if (this.bank == false) {

            } else {
                getProcessReceivedList({
                    pageNo: this.page,
                    pageSize: this.pageSize,
                    createTime: this.createTime,
                    formIds: this.formIds,
                    name: this.name,
                    status: this.status,
                    creator: this.creator,
                }).then(res => {
                    console.log(res)
                    if (res.code == 0) {
                        if (res.data.list.length != 0) {
                            this.total = res.data.total / 10
                            res.data.list.forEach((e) => {
                                e.digest = JSON.parse(e.digest)
                                e.isShow = false
                            })
                            this.tableData = this.tableData.concat(res.data.list)
                            console.log(this.tableData);
                            // scrollDisabled.value = false
                            if (res.data.list.length < 10) { // 判断是否有下一页
                                this.oldPage = this.page
                                this.page = this.page
                                this.bank = false
                            } else { // 存在下一页
                                this.oldPage = this.page
                                this.page = this.page + 1
                                this.bank = true
                            }
                        } else {
                            if (this.soType == 1) {
                                if (this.page == 1) {
                                    this.$message({
                                        message: '没有搜索到消息',
                                        type: 'warning',
                                    });
                                    let bankList = [];
                                    this.bank = true
                                    this.oldPage = 0
                                    this.page = 1
                                    this.tableData = bankList
                                    console.log(this.$emit);
                                    // this.$emit("showlist");
                                } else {
                                    let bankList = this.tableData;
                                    this.bank = false
                                    this.oldPage = this.page
                                    this.pageNum = this.page
                                    this.tableData = bankList
                                }
                            } else {
                                let bankList = this.tableData;
                                if (this.page == 1) {
                                } else {
                                    // this.$message({
                                    //     message: '没有更多消息了',
                                    //     type: 'warning',
                                    // });
                                }
                                this.bank = false
                                this.oldPage = this.page
                                this.pageNum = this.page
                                this.tableData = bankList
                            }

                            // scrollDisabled.value = false
                        }
                    } else {
                        ElMessage.error(res.data.msg)
                    }
                    this.disabled = false
                    this.loading = false
                })

            }

        },
        // 详情数据
        openDetails(row) {
            console.log(row);
        },
        // 打开详情界面 
        handleClick(row) {
            row.taskType = 4;
            row.taskId = row.id;
            row.id = row.processInstance.id;
            this.$emit('openDrawer', row)
        }
    },
}
</script>

<style scoped>
.conetents {
    width: 100%;
    height: calc(100vh - 280px);
    margin-top: 16px;
    border-radius: 6px;
}

.c-header {
    width: 100%;
    height: 40px;
    background-color: #5669ec;
    border-radius: 6px 6px 0px 0px;
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    box-sizing: border-box;
}

.font {
    color: #fafbfc;
    font-size: 13px;
    line-height: 40px;
}

.c-list {
    width: 100%;
    height: calc(100vh - 320px);
    background-color: #f3f4f6;
    border-radius: 0px 0px 6px 6px;
    overflow-y: auto;
}

.c-list-box {
    display: flex;
    justify-content: left;
    min-height: 120px;
    border-bottom: 1px solid rgba(236, 236, 236, 1);
    padding: 18px 16px 18px 24px;
    box-sizing: border-box;
}

.list-box {
    /* max-height: 84px; */
    padding: 0 16px;
    box-sizing: border-box;
    /* background-color: aqua; */
}

.text-cs {
    color: var(--字体黑, rgba(27, 27, 27, 1));
    font-family: "Noto Sans SC";
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
}

.header-bg {
    height: 24px;
    display: flex;
    justify-content: left;
}
</style>